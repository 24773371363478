import { h } from "preact";

// import Header from "./header";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  getAuth0Audience,
  getAuth0ClientId,
  getAuth0Domain,
  getUserManagerHost,
} from "../configs/env";
import { GoogleOAuthProvider } from "@react-oauth/google";
import UserAuthProvider from "../context/UserAuthContext";
import AppRouter from "./router";
import ToasterContext from "../context/ToasterContext";
import styles from "./app.css";

const App = () => {
  return (
   
        <GoogleOAuthProvider clientId="864690442768-tcge6fi58ohce2kbm667cjpide1rsjeu.apps.googleusercontent.com">
          <Auth0Provider
            domain={getAuth0Domain()}
            clientId={getAuth0ClientId()}
            authorizationParams={{
              redirect_uri: `${getUserManagerHost()}`,
              audience: `https://${getAuth0Audience()}/api/v2/`,
              // audience: "https://dev-pb3ebqwfbaxu28jb.us.auth0.com/api/v2/",
            }}
          >
            <ToasterContext>
              <UserAuthProvider>
                <AppRouter />
              </UserAuthProvider>
            </ToasterContext>
          </Auth0Provider>
        </GoogleOAuthProvider>

  );
};
export default App;
