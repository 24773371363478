
/**
 * This file contains all the environment variables that are used in the application.
 */
const auth0Envs:any = {
    'editor-ur1': {
        audience: 'korra.us.auth0.com',
        domain: 'auth0-second.korra.ai',
        clientId: 'K00yRyB92IwQqPABvSB0G8L0jRpjIrOG',
    }
}

export const getAppPath = () => {
    const path = window.location.pathname;
    // for company-specific auth0 applications, we need to have the redirect_uri starts with the company-specific path
    for (const [key, value] of Object.entries(auth0Envs)) {
        if (window.location.hostname.startsWith(key)) {
            return key;
        }
    }

    return ""
}

/**
 * 
 * @returns the auth0 envs for the current url
 */
const getAuth0Envs:any = () => {
    // const { protocol, host, path } = analyzeUrl(window.location.origin);
    // loop over the auth0 envs and find the one that matches the current url
    //
    for (const [key, value] of Object.entries(auth0Envs)) {
        if (window.location.hostname.startsWith(key)) {
            return value;
        }
    }

    return { 
        clientId:process.env.AUTH0_CLIENT_ID,
        audience:process.env.AUTH0_AUDIENCE,
        domain:process.env.AUTH0_DOMAIN, 
    }
    
    
}

export const getAuth0Audience = (): string | undefined => {
    return (getAuth0Envs() as any).audience;
};
export const getAuth0Domain = (): string | undefined => {
    return (getAuth0Envs() as any).domain;
};
export const getAuth0ClientId = (): string | undefined => {
    return (getAuth0Envs() as any).clientId;
}
// export const getEnvName = (): string | undefined => {
//     return process.env.ENVNAME;
// }

export const getAiHost = (): string | undefined => {
    return process.env.AI_HOST;
}

export const getDbxHost = (): string | undefined => {
    return process.env.DBX_HOST;
}
// export const getDashboardHost = (): string | undefined => {
//     return process.env.DASHBOARD_HOST;
// }

export const getAuthHost = (): string | undefined => {
    return process.env.AUTH_HOST;
}

export const getEditorHost = (): string | undefined => {
    return process.env.EDITOR_HOST ;
}

// export const getPricingTableId = (): string | undefined => {
//     return process.env.STRIPE_PRICING_TABLE_ID;
// }

// export const getStripePublicKey = (): string | undefined => {
//     return process.env.STRIPE_PUBLIC_KEY;
// }

// export const getStatsHost = (): string | undefined => {
//     return process.env.STATS_HOST;
// }

export const getUserManagerHost = (): string | undefined => {
    return window.location.origin;
}


// export const getAiSearchHost = (): string | undefined => {
//     return process.env.AI_SEARCH_HOST; 
// }

// export const getCdnName = (): string | undefined => {
//     return process.env.CDN_NAME; 
// }

// export const get_IS_CDN77 = (): boolean => {
//     return process.env.CDN_NAME === 'CDN77' ? true : false; 
// }
