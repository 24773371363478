import { IUserWorkspace, UserWorkspace } from "../models/user-workspace";
import {
  getAiHost,
  getAuth0ClientId,
  getAuthHost,
  getDbxHost,
} from "../configs/env";
import { IResponse } from "../models/http";
import { IUser } from "../models/user";

export const updateUserPlan = async (
  workspaceId: string,
  plan: string,
  token: string,
  handleUserUpdateRes: (message: string, flag: boolean) => void
): Promise<IUser> => {
  const url = `${getAiHost()}/payments/grant`;
  try {
    const res = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        workspaceId,
        plan,
      }),
      method: "POST",
      mode: "cors",
    });

    if (!res.ok) {
      handleUserUpdateRes("something went wrong", false);
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    handleUserUpdateRes("User Plan updated successfully", true);
    return data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error; // rethrow the error after logging it
  }
};

export const updateUserGpt = async (
  workspaceId: string,
  gptModel: string,
  handleUserUpdateRes: (message: string, flag: boolean) => void
): Promise<IUser> => {
  const url = `${getAiHost()}/config_manager/update_gpt/${workspaceId}/${gptModel}`;
  try {
    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
    });

    if (!res.ok) {
      handleUserUpdateRes("something went wrong", false);
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    handleUserUpdateRes("User GPT Model updated successfully", true);
    return data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error; // rethrow the error after logging it
  }
};
export const getUserByEmail = async (
  email: string,
  idToken: string
): Promise<IUser | null> => {
  // const url = `https://auth1.korra.ai/users/email/${email}`;
  const url = `${getAuthHost()}/users/email/${email}`;
  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
      "Client-Id": `${getAuth0ClientId()}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUser> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

export const getUserGptModel = async (workspaceId: string) => {
  const url = `${getAiHost()}/config_manager/get_gpt/${workspaceId}`;
  const res = await fetch(url, {
    method: "GET",
    mode: "cors",
  });

  const userResp = await res.json();
  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp;
  }
};

export const fetchUserDetails = async (
  email: string
): Promise<IUser | null> => {
  const url = `${getDbxHost()}/dbx/get-user-details?email=${email}`;
  const res = await fetch(<any>url, {
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUser> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

export const getUserWorkspace = async (
  userId: string,
  userIdToken: string
): Promise<IUserWorkspace> => {
  const url = `${getAuthHost()}/user-workspace/${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userIdToken}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUserWorkspace> = await res.json();

  // //new user registered with workspaceId
  // if(userResp.data?.workspaceId){
  //   return  userResp.data;
  // }

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return new UserWorkspace(userResp.data);
  }
};

export const getUsersCounter = async (
  workspaceId:string,
  userId: string,
  userIdToken: string
): Promise<any> => {
  const url = `${getAiHost()}/user_manager/users/counter/workspace/${workspaceId}?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userIdToken}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp = await res.json();

  if (userResp) {
    return userResp
  } else {
    throw new Error(userResp.error.message);
  }
};